<template>
    <div class="levelAction">
        <div class="group">
            <div class="label label-active">等级名称</div>
            <a-input v-model="Name" class="group-item"></a-input>
        </div>
        <div class="group">
            <div class="label">返佣比例</div>
            <div class="group-item">
                <a-radio-group v-model="CommissionReturnType" @change="changeCommission">
                    <a-radio :value="1">
                        比例返佣
                    </a-radio>
                    <a-radio :value="2">
                        固定返佣
                    </a-radio>
                </a-radio-group>

                <div class="return-commission-level" v-if="CommissionReturnType==1">
                    <a-input addon-before="一级返佣" v-model="CommissionFirst" addon-after="%"/>
                    <a-input addon-before="二级返佣" v-model="CommissionSecond" addon-after="%"/>
                </div>
                <div class="return-commission-level" v-if="CommissionReturnType==2">
                    <a-input addon-before="一级返佣" v-model="CommissionFirst" addon-after="元"/>
                    <a-input addon-before="二级返佣" v-model="CommissionSecond" addon-after="元"/>
                </div>
                <!--<p class="return-commission-text">分销商下线购买商品后获得的分销返佣</p>-->
                <!--<div class="return-commission-level">-->
                <!--<a-input addon-before="一级推荐" addon-after="元"/>-->
                <!--<a-input addon-before="二级推荐" addon-after="元"/>-->
                <!--</div>-->
                <p class="return-commission-text">分销商升级后，上级可获得推荐奖。0或空则不发放</p>
            </div>
        </div>
        <div class="group">
            <div class="label">升级条件</div>
            <div class="group-item" style="width: 460px">
                <a-radio-group v-model="UpdateType" @change="changeUpdateType">
                    <a-radio :value="1">
                        满足所有勾选条件
                    </a-radio>
                    <a-radio :value="2">
                        满足勾选条件之一即可
                    </a-radio>
                </a-radio-group>
                <div class="drop">
                    <div class="drop-list" v-for="(item,index) in updateList">
                        <a-checkbox @change="changeUpdate" :checked="item.value" :value="item.val" class="drop-item">
                            <span class="drop-item-text">{{item.name}}</span>
                        </a-checkbox>
                        <a-input type="number" v-model="updateList[index]['price']" class="upgrade-conditions-input"
                                 addon-after="元"/>
                    </div>
                </div>

                <p class="return-commission-text">开启后不满足指定条件自动降级，关闭后则该等级不会自动降级。</p>
            </div>
        </div>
        <div class="group">
            <div class="label">自动升级</div>
            <div class="group-item" style="width: 460px">
                <a-radio-group v-model="DropType" @change="changeDropType">
                    <a-radio :value="1">
                        满足所有勾选条件
                    </a-radio>
                    <a-radio :value="2">
                        满足勾选条件之一即可
                    </a-radio>
                </a-radio-group>
                <div class="drop">
                    <div v-for="(item,index) in dropList" class="drop-list">
                        <a-checkbox @change="changeDrop" :checked="item.value" :value="item.val" class="drop-item">
                            <span class="drop-item-text">{{ item.name }}</span>
                        </a-checkbox>
                        <div style="display: flex;flex: 1;margin-left: 10px">
                            <a-input type="number" v-model="dropList[index]['dayNum']" class="conditions-item-first"
                                     addon-after="天内，少于"/>
                            <a-input type="number" v-model="dropList[index]['price']" class="conditions-item"
                                     :addon-after="item.val=='drop_offline_order_num_inday'?'单':'元'"/>
                        </div>

                    </div>

                </div>
                <p class="return-commission-text">开启后不满足指定条件自动降级，关闭后则该等级不会自动降级。</p>
            </div>
        </div>

        <div class="group">
            <div class="label label-active">权重</div>
            <div class="group-item">
                <a-input-number style="margin-bottom: 10px;width: 100%" v-model="Weight" :min="1"/>
                <p class="return-commission-text">等级权重，数字不能重复，数字越大等级越高。按设置的权重大小从低到高进行升级。</p>
            </div>

        </div>
        <div class="group">
            <div class="label">&nbsp;&nbsp;</div>
            <div class="group-item">
                <!--<a-button style="width: 80px" class="search-item" >重 置</a-button>-->
                <a-button style="width: 80px;color: #ffffff;margin-left: 25px" class="search-item" type="primary" @click="_action()">保存</a-button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "levelAction",
        data() {
            return {
                CommissionReturnType: 1,
                Commission: '',
                updateList: [
                    {'name': '一级分销商达', 'val': 'update_frist_distributor_num', 'price': '', 'value': false},
                    {'name': '二级分销商达', 'val': 'update_second_distributor_num', 'price': '', 'value': false},
                    {'name': '下线客户数达', 'val': 'update_offline_customers_number', 'price': '', 'value': false},
                    {'name': '下线总人数达', 'val': 'update_offline_customers_total_number', 'price': '', 'value': false},
                    {'name': '分销订单金额', 'val': 'update_offline_order_money', 'price': '', 'value': false},
                ],
                dropList: [
                    {
                        'name': '下线总订单量',
                        'val': 'drop_offline_order_num_inday',
                        'keyDay': 'drop_offline_order_num_less',
                        'price': '0',
                        'dayNum': '',
                        'value': false
                    },
                    {
                        'name': '下线总订单金额',
                        'val': 'drop_offline_order_money_inday',
                        'keyDay': 'drop_offline_order_money_less',
                        'price': '0',
                        'dayNum': '',
                        'value': false
                    },
                    {
                        'name': '内购订单金额',
                        'keyDay': 'drop_internal_purchase_order_money_less',
                        'val': 'drop_internal_purchase_order_money_inday',
                        'price': '0',
                        'dayNum': '',
                        'value': false
                    },

                ],
                updateValue: {},
                Id: this.$route.query.id ? this.$route.query.id : '',
                Name: '',
                Weight: '',
                CommissionFirst: '',//一级返佣
                CommissionSecond: '',//二级返佣
                UpdateType: 1,//升级条件类型
                DropType: 1,//降级条件类型
                DropValue: {},

            }
        },
        created() {
            if (this.Id) {
                this.$route.meta.title = '编辑分销等级';
                this.loadData();
            }
        },
        methods: {
            loadData() {
                let self = this;
                this.$message.loading('加载中....', 0);
                this.$axios.get(1244, {
                    Id: self.Id,
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        let infoData = res.data.data;
                        self.Name = infoData.Name;
                        self.Weight = infoData.Weight;
                        self.CommissionReturnType = infoData.CommissionReturnType;
                        self.CommissionFirst = infoData.CommissionFirst;
                        self.CommissionSecond = infoData.CommissionSecond;
                        self.UpdateType = infoData.UpdateType;
                        self.DropType = infoData.DropType;
                        if (infoData.update.length > 0) {
                            for (let j = 0; j < self.updateList.length; j++) {
                                for (let i = 0; i < infoData.update.length; i++) {
                                    if (infoData.update[i].Label == self.updateList[j].val) {
                                        if(infoData.update[i].UpdateNum){
                                            self.updateList[j].value = true;
                                            self.updateList[j].price = infoData.update[i].UpdateNum;
                                        }

                                    }
                                }
                            }
                        }
                        if (infoData.drop.length > 0) {
                            for (let i = 0; i < self.dropList.length; i++) {
                                for (let j = 0; j < infoData.drop.length; j++) {
                                    if (infoData.drop[j].Label == self.dropList[i].val) {
                                        if(infoData.drop[j][self.dropList[i].val]){
                                            self.dropList[i].dayNum = infoData.drop[j][self.dropList[i].val];
                                            self.dropList[i].price = infoData.drop[j][self.dropList[i].keyDay];
                                            self.dropList[i].value = true;
                                        }

                                    }
                                }
                            }
                        }

                    } else {
                        this.$message.error(res.data.msg);
                    }


                })
            },
            _action() {
                let self = this;
                let data = {
                    "Id": self.Id,
                    "Name": self.Name,
                    "CommissionReturnType": self.CommissionReturnType,
                    "Weight": self.Weight,
                    "CommissionFirst": self.CommissionFirst,
                    "CommissionSecond": self.CommissionSecond,
                    "UpdateType": self.UpdateType,
                    "DropType": self.DropType,
                };
                for (let i = 0; i < self.updateList.length; i++) {
                    if(self.updateList[i].value){
                        if (self.updateList[i].price) {
                            data[self.updateList[i].val] = self.updateList[i].price;
                        } else {
                            this.$message.error('请输入' + self.updateList[i].name);
                            return false;
                            break;
                        }
                    }else{
                        data[self.updateList[i].val] = '';
                    }

                    // if (self.updateValue.hasOwnProperty(self.updateList[i].val)) {
                    //     if (self.updateList[i].price) {
                    //         data[self.updateList[i].val] = self.updateList[i].price;
                    //     } else {
                    //         this.$message.error('请输入' + self.updateList[i].name);
                    //         return false;
                    //         break;
                    //     }
                    // }
                }
                for (let i = 0; i < self.dropList.length; i++) {
                    if(self.dropList[i].value){
                        //多少天内
                        if (self.dropList[i].dayNum) {
                            data[self.dropList[i].val] = self.dropList[i].dayNum;
                        } else {
                            this.$message.error('请输入' + self.dropList[i].name + '多少天内');
                            return false;
                            break;
                        }
                        //多少元、单
                        if (self.dropList[i].price) {
                            data[self.dropList[i].keyDay] = self.dropList[i].price;
                        } else {
                            this.$message.error('请输入' + self.dropList[i].name + '少于多少');
                            return false;
                            break;
                        }
                    }else{
                        data[self.dropList[i].val]    = '';
                        data[self.dropList[i].keyDay] = '';
                    }
                    // if (self.DropValue.hasOwnProperty(self.dropList[i].val)) {
                    //     //多少天内
                    //     if (self.dropList[i].dayNum) {
                    //         data[self.dropList[i].val] = self.dropList[i].dayNum;
                    //     } else {
                    //         this.$message.error('请输入' + self.dropList[i].name + '多少天内');
                    //         return false;
                    //         break;
                    //     }
                    //     //多少元、单
                    //     if (self.dropList[i].price) {
                    //         data[self.dropList[i].keyDay] = self.dropList[i].price;
                    //     } else {
                    //         this.$message.error('请输入' + self.dropList[i].name + '少于多少');
                    //         return false;
                    //         break;
                    //     }
                    //
                    // }
                }

                this.$axios.post(1241, data, res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        self.$router.push({path: '/distributionLevel'})
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    console.log(res);
                })
            },
            changeCommission(e) {

                this.CommissionReturnType = e.target.value;
            },
            changeDropType(e) {
                this.DropType = e.target.value;
            },
            changeUpdateType(e) {
                this.UpdateType = e.target.value;

            },
            changeUpdate(e) {
                for (var i = 0; i < this.updateList.length; i++) {
                    if (this.updateList[i].val == e.target.value) {
                        this.updateList[i].value = !this.updateList[i].value;
                    }
                }
                // this.updateValue[e.target.value] = e.target.checked;
            },
            changeDrop(e) {
                for (var i = 0; i < this.dropList.length; i++) {
                    if (this.dropList[i].val == e.target.value) {
                        this.dropList[i].value = !this.dropList[i].value;
                    }
                }
                // this.DropValue[e.target.value] = e.target.checked;
            },
        }
    }
</script>

<style scoped lang="less">
    .levelAction {
        padding: 28px 0px 50px 69px;
        width: 100%;
        background: #ffffff;
        .group {
            display: flex;
            /*align-items: center;*/
            margin-bottom: 15px;
            .label {
                color: #666666;
                font-size: 12px;
                margin-right: 22px;
                width: 60px;
            }
            .label-active {
                position: relative;
                padding-left: 8px;
            }
            .label-active::after {
                content: '*';
                width: 5px;
                height: 5px;
                position: absolute;
                top: 4%;
                left: 0;

                color: red;
            }
            .group-item {
                width: 386px;
                .return-commission-level {
                    display: flex;
                    margin-bottom: 15px;
                    margin-top: 15px;
                }
                .return-commission-text {
                    color: #A6A6A6;
                    font-size: 12px;
                }
                .drop {
                    background: #F8F8F8;
                    opacity: 1;
                    border-radius: 0px 3px 3px 0px;
                    margin-top: 15px;
                    padding: 10px;
                    margin-bottom: 15px;
                    .drop-list {
                        display: flex;
                        margin-bottom: 15px;
                        align-items: center;
                        .drop-item {
                            width: 120px;
                            display: flex;
                            align-items: center;
                            .drop-item-text {
                                width: 100px;
                                display: block;
                            }
                        }
                    }
                }

            }
        }

    }
</style>